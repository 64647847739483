﻿(function () {
    window.installedModules = null;

    window.verifyInstalledModule = function (packageName) {
        var modulesInfo = null;

        if (isNullOrEmpty(window.installedModules)) {
            modulesInfo = localStorage.getItem("installed_modules");

            if (isNullOrBlank(modulesInfo)) { return false; }

            window.installedModules = JSON.parse(modulesInfo);
        }

        if (isNullOrEmpty(window.installedModules)) { return false; }
        if (isNullOrBlank(packageName)) { return false; }

        packageName = packageName.toUpperCase();

        for (var i = 0, module = null; (module = window.installedModules[i]) ; i++) {
            if (isNullOrBlank(module.Package)) { continue; }
            if (module.Package.toUpperCase() === packageName) {
                return true;
            }
        }

        return false;
    };

    window.verifyInstalledModuleByGUID = function (guid) {
        var modulesInfo = null;

        if (isNullOrEmpty(window.installedModules)) {
            modulesInfo = localStorage.getItem("installed_modules");

            if (isNullOrBlank(modulesInfo)) { return false; }

            window.installedModules = JSON.parse(modulesInfo);
        }

        if (isNullOrEmpty(window.installedModules)) { return false; }
        if (isNullOrBlank(guid)) { return false; }

        guid = guid.toUpperCase();

        for (var i = 0, module = null; (module = window.installedModules[i]) ; i++) {
            if (module.GUID.toUpperCase() === guid) {
                return true;
            }
        }

        return false;
    };

    window.storeModuleData = function () {
        localStorage.removeItem("installed_modules");
        if (isNullOrEmpty(window.installedModules)) { return false; }
        var modulesInfo = JSON.stringify(window.installedModules);
        localStorage.setItem("installed_modules", modulesInfo);
    };

    window.apiServiceCall = function (module, serviceName, methodName, content, callback, errorFunc) {
        var envelope = {
            "sessiontoken": sessionStorage.getItem("sessiontoken")
        };

        var customizationkey = sessionStorage.getItem("customizationkey");

        if (!isNullOrEmpty(customizationkey)) {
            envelope.customizationkey = customizationkey;
        }

        if (content != null) { envelope.content = content; }

        $.ajax({
            method: "POST",
            url: (window.baseAppPath || "") + "/api/" + module + "/" + serviceName + "/" + methodName,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: JSON.stringify(envelope),
            success: function (result) {
                if (!isNullOrEmpty(result.sessiontoken)) {
                    sessionStorage.setItem("sessiontoken", result.sessiontoken);
                }
                callback(result.content, result.message);
            },
            error: function (jqReq, txtStatus, errorMsg) {
                if (jqReq.status == 560 || jqReq.status == 401 || jqReq.status == 403) { // Business Exception or Security Exception
                    try {
                        var errorResult = JSON.parse(jqReq.responseText);
                        if (!isNull(errorFunc) && errorFunc instanceof Function) {
                            errorFunc(errorResult.message, errorResult.warninglevel);
                        } else {
                            alert(errorResult.message);
                        }
                    } catch (e) {
                        if (jqReq.status == 560) {
                            alert(jqReq.responseText);
                        }
                    }
                    if (jqReq.status == 401 || jqReq.status == 403) { window.top.location = "/#/login" } // Security Exception, redirect to login
                } else if (jqReq.readyState == 4) { // Unknow error
                    console.log(jqReq.responseText);

                    window.lastApiError = jqReq.responseText;

                    if (!isNull(errorFunc) && errorFunc instanceof Function) {
                        errorFunc(window.language.getVerb("unknow_error"), 2);
                    }
                }
            }
        });
    };

    window.getAppResource = function (module, serviceName, methodName, params, requireAuth, callback, errorFunc) {
        var resourceUrl = (window.baseAppPath || "") + "/resource/" + module + "/" + serviceName + "/" + methodName + "/?";
        var customizationkey = sessionStorage.getItem("customizationkey");
        var querystring = "";

        if (requireAuth) {
            querystring += "sessiontoken=" + encodeURIComponent(sessionStorage.getItem("sessiontoken"));
        }

        if (!isNullOrEmpty(customizationkey)) {
            if (querystring.length > 0) { querystring += "&"; }
            querystring += "customizationkey=" + sessionStorage.getItem("customizationkey");
        }

        if (!isNullOrEmpty(params)) {
            if (querystring.length > 0) { querystring += "&"; }
            querystring += params;
        }

        resourceUrl += querystring;

        $.ajax({
            method: "GET",
            url: resourceUrl,
            success: function (result) {
                callback(result);
            },
            error: function (jqReq, txtStatus, errorMsg) {
                errorFunc(jqReq.responseText);
            }
        });
    };

    window.getAppResourceUrl = function (module, serviceName, methodName, params, requireAuth) {
        var resourceUrl = (window.baseAppPath || "") + "/resource/" + module + "/" + serviceName + "/" + methodName + "/?";
        var customizationkey = sessionStorage.getItem("customizationkey");
        var querystring = "";

        if (requireAuth) {
            querystring += "sessiontoken=" + encodeURIComponent(sessionStorage.getItem("sessiontoken"));
        }

        if (!isNullOrEmpty(customizationkey)) {
            if (querystring.length > 0) { querystring += "&"; }
            querystring += "customizationkey=" + sessionStorage.getItem("customizationkey");
        }

        if (!isNullOrEmpty(params)) {
            if (querystring.length > 0) { querystring += "&"; }
            querystring += params;
        }

        resourceUrl += querystring;

        return resourceUrl;
    };

    window.logOff = function () {
        sessionStorage.clear();
        localStorage.removeItem("lastsessiontoken");
        localStorage.removeItem("passwordhash");
        localStorage.removeItem("storepassword");
        return true;
    };

    window.localServiceCall = function (handler, methodName, content, callback, errorFunc) {
        var authToken = sessionStorage.getItem("sessiontoken");
        var language = window.cultureInfo;

        $.ajax({
            method: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("Content-Language", language);

                if (!isNullOrBlank(authToken)) {
                    request.setRequestHeader("X-Auth-Token", authToken);
                }
            },
            url: "http://localhost:48080/" + handler + "/" + methodName,
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            data: (!isNull(content) ? JSON.stringify(content) : null),
            success: function (result) {
                if (!isNull(callback) && callback instanceof Function) {
                    if (!isNull(result)) {
                        if (result.hasOwnProperty('content')) {
                            callback(result.content);
                        } else {
                            callback(result);
                        }
                    } else {
                        callback(null);
                    }
                }
            },
            error: function (jqReq, txtStatus, errorMsg) {
                if (jqReq.status == 560) {
                    try {
                        var errorResult = JSON.parse(jqReq.responseText);
                        if (!isNull(errorFunc) && errorFunc instanceof Function) {
                            errorFunc(errorResult.message, errorResult.warninglevel);
                        } else {
                            alert(errorResult.message);
                        }
                    } catch (e) {
                        alert(jqReq.responseText);
                    }
                } else { // Unknow error
                    if (!isNull(errorFunc) && errorFunc instanceof Function) {
                        errorFunc(isNull(window.language) ? "Unknow Error" : window.language.getVerb("unknow_error"), 2);
                    }
                }
            }
        });
    };
})();