﻿(function () {
    window.language = {};

    window.defaultCurrency = parseInt(sessionStorage.getItem("defaultCurrency") || 0);
    window.currentExchangeMark = parseInt(sessionStorage.getItem("currentExchangeMark") || 0);
    window.exchangeRatios = sessionStorage.getItem("exchangeRatios");

    if (!isNullOrBlank(window.exchangeRatios)) {
        window.exchangeRatios = JSON.parse(window.exchangeRatios);
    }

    window.storeExchangeData = function () {
        sessionStorage.setItem("defaultCurrency", window.defaultCurrency);
        sessionStorage.setItem("currentExchangeMark", window.currentExchangeMark);
        sessionStorage.setItem("exchangeRatios", JSON.stringify(window.exchangeRatios));
    };

    window.language.updateCultureInfo = function () {
        window.cultureInfo = sessionStorage.getItem("currentlanguage");

        if (isNullOrEmpty(window.cultureInfo)) {
            window.cultureInfo = (navigator.language || navigator.userLanguage);
        }
    };

    window.language.updateCultureInfo();

    window.language.getMessage = function (code, defaultText) {
        var result = localStorage.getItem(code);
        if (isNullOrEmpty(result)) {
            if (code == "msg_successfuloperation") {
                return "Operation successfully completed";
            } else if (isNullOrBlank(defaultText)) {
                return "Undefined Message - " + code;
            } else {
                return defaultText;
            }
        }
        return result;
    };

    window.language.getVerb = function (code) {
        var result = localStorage.getItem("verb_" + code);
        if (isNullOrEmpty(result)) { return code; }
        return result;
    };

    window.language.hasVerb = function (code) {
        return !isNullOrEmpty(localStorage.getItem("verb_" + code));
    };

    window.formatter = {};

    window.formatter["en-US"] = {
        shortDate: "MM/dd/yy",
        date: "MMM dd, yyyy",
        dateTime: "M/d/yy h:mm a",
        shortDateTime: "M/d h:mm a",
        shortTime: "h:mm a",
        timeOfDay: "M/d h:mm",
        fullDateTime: "M/d/yy h:mm:ss a",
        inputDateMask: "date-us",
        inputDecimalPrecisionMask: "decimalprecision-us",
        inputDecimalMask: "decimal-us",
        inputSignedDecimalMask: "signed-decimal-us",
        halfTimeOptions: [
            { value: "00:00", text: "00:00 am" }, { value: "00:30", text: "00:30 am" }, { value: "01:00", text: "01:00 am" }, { value: "01:30", text: "01:30 am" },
            { value: "02:00", text: "02:00 am" }, { value: "02:30", text: "02:30 am" }, { value: "03:00", text: "03:00 am" }, { value: "03:30", text: "03:30 am" },
            { value: "04:00", text: "04:00 am" }, { value: "04:30", text: "04:30 am" }, { value: "05:00", text: "05:00 am" }, { value: "05:30", text: "05:30 am" },
            { value: "06:00", text: "06:00 am" }, { value: "06:30", text: "06:30 am" }, { value: "07:00", text: "07:00 am" }, { value: "07:30", text: "07:30 am" },
            { value: "08:00", text: "08:00 am" }, { value: "08:30", text: "08:30 am" }, { value: "09:00", text: "09:00 am" }, { value: "09:30", text: "09:30 am" },
            { value: "10:00", text: "10:00 am" }, { value: "10:30", text: "10:30 am" }, { value: "11:00", text: "11:00 am" }, { value: "11:30", text: "11:30 am" },
            { value: "12:00", text: "12:00 pm" }, { value: "12:30", text: "12:30 pm" }, { value: "13:00", text: "01:00 pm" }, { value: "13:30", text: "01:30 pm" },
            { value: "14:00", text: "02:00 pm" }, { value: "14:30", text: "02:30 pm" }, { value: "15:00", text: "03:00 pm" }, { value: "15:30", text: "03:30 pm" },
            { value: "16:00", text: "04:00 pm" }, { value: "16:30", text: "04:30 pm" }, { value: "17:00", text: "05:00 pm" }, { value: "17:30", text: "05:30 pm" },
            { value: "18:00", text: "06:00 pm" }, { value: "18:30", text: "06:30 pm" }, { value: "19:00", text: "07:00 pm" }, { value: "19:30", text: "07:30 pm" },
            { value: "20:00", text: "08:00 pm" }, { value: "20:30", text: "08:30 pm" }, { value: "21:00", text: "09:00 pm" }, { value: "21:30", text: "09:30 pm" },
            { value: "22:00", text: "10:00 pm" }, { value: "22:30", text: "10:30 pm" }, { value: "23:00", text: "11:00 pm" }, { value: "23:30", text: "11:30 pm" }],
        dayOfWeek: {
            sunday: { text: "Sunday", abbrv: "SUN", letter: "S" },
            monday: { text: "Monday", abbrv: "MON", letter: "M" },
            tuesday: { text: "Tuesday", abbrv: "TUE", letter: "T" },
            wednesday: { text: "Wednesday", abbrv: "WED", letter: "W" },
            thursday: { text: "Thursday", abbrv: "THU", letter: "T" },
            friday: { text: "Friday", abbrv: "FRI", letter: "F" },
            saturday: { text: "Saturday", abbrv: "SAT", letter: "S" }
        }
    };

    window.formatter["pt-BR"] = {
        shortDate: "dd/MM/yy",
        date: "dd/MM/yyyy",
        dateTime: "dd/MM/yyyy HH:mm",
        shortDateTime: "dd/MM HH:mm",
        shortTime: "HH:mm",
        timeOfDay: "dd/MM HH:mm",
        fullDateTime: "dd/MM/yyyy HH:mm:ss",
        inputDateMask: "date",
        inputDecimalPrecisionMask: "decimalprecision",
        inputDecimalMask: "decimal",
        inputSignedDecimalMask: "signed-decimal",
        halfTimeOptions: [
            { value: "00:00", text: "00:00" }, { value: "00:30", text: "00:30" }, { value: "01:00", text: "01:00" }, { value: "01:30", text: "01:30" },
            { value: "02:00", text: "02:00" }, { value: "02:30", text: "02:30" }, { value: "03:00", text: "03:00" }, { value: "03:30", text: "03:30" },
            { value: "04:00", text: "04:00" }, { value: "04:30", text: "04:30" }, { value: "05:00", text: "05:00" }, { value: "05:30", text: "05:30" },
            { value: "06:00", text: "06:00" }, { value: "06:30", text: "06:30" }, { value: "07:00", text: "07:00" }, { value: "07:30", text: "07:30" },
            { value: "08:00", text: "08:00" }, { value: "08:30", text: "08:30" }, { value: "09:00", text: "09:00" }, { value: "09:30", text: "09:30" },
            { value: "10:00", text: "10:00" }, { value: "10:30", text: "10:30" }, { value: "11:00", text: "11:00" }, { value: "11:30", text: "11:30" },
            { value: "12:00", text: "12:00" }, { value: "12:30", text: "12:30" }, { value: "13:00", text: "13:00" }, { value: "13:30", text: "13:30" },
            { value: "14:00", text: "14:00" }, { value: "14:30", text: "14:30" }, { value: "15:00", text: "15:00" }, { value: "15:30", text: "15:30" },
            { value: "16:00", text: "16:00" }, { value: "16:30", text: "16:30" }, { value: "17:00", text: "17:00" }, { value: "17:30", text: "17:30" },
            { value: "18:00", text: "18:00" }, { value: "18:30", text: "18:30" }, { value: "19:00", text: "19:00" }, { value: "19:30", text: "19:30" },
            { value: "20:00", text: "20:00" }, { value: "20:30", text: "20:30" }, { value: "21:00", text: "21:00" }, { value: "21:30", text: "21:30" },
            { value: "22:00", text: "22:00" }, { value: "22:30", text: "22:30" }, { value: "23:00", text: "23:00" }, { value: "23:30", text: "23:30" }],
        dayOfWeek: {
            sunday: { text: "Domingo", abbrv: "DOM", letter: "D" },
            monday: { text: "Segunda-Feira", abbrv: "SEG", letter: "S" },
            tuesday: { text: "Terça-Feira", abbrv: "TER", letter: "T" },
            wednesday: { text: "Quarta-Feira", abbrv: "QUA", letter: "Q" },
            thursday: { text: "Quinta-Feira", abbrv: "QUI", letter: "Q" },
            friday: { text: "Sexta-Feira", abbrv: "SEX", letter: "S" },
            saturday: { text: "Sábado", abbrv: "SAB", letter: "S" }
        }
    };

    var base64Converter = { _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", encode: function (e) { var t = ""; var n, r, i, s, o, u, a; var f = 0; e = base64Converter._utf8_encode(e); while (f < e.length) { n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 } t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a) } return t }, decode: function (e) { var t = ""; var n, r, i; var s, o, u, a; var f = 0; e = e.replace(/[^A-Za-z0-9\+\/\=]/g, ""); while (f < e.length) { s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) } if (a != 64) { t = t + String.fromCharCode(i) } } t = base64Converter._utf8_decode(t); return t }, _utf8_encode: function (e) { e = e.replace(/\r\n/g, "\n"); var t = ""; for (var n = 0; n < e.length; n++) { var r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } } return t }, _utf8_decode: function (e) { var t = ""; var n = 0; var r = c1 = c2 = 0; while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } } return t } }

    window.converter = {
        base64: base64Converter,
        parseDateISOString: function (inputValue, utc) {
            if (!isNullOrEmpty(inputValue) && inputValue instanceof Date) {
                var year = ("0000" + (utc ? inputValue.getUTCFullYear() : inputValue.getFullYear()).toString());
                var month = ("00" + ((utc ? inputValue.getUTCMonth() : inputValue.getMonth()) + 1).toString());
                var day = ("00" + (utc ? inputValue.getUTCDate() : inputValue.getDate()).toString());
                var hours = ("00" + (utc ? inputValue.getUTCHours() : inputValue.getHours()).toString());
                var minutes = ("00" + (utc ? inputValue.getUTCMinutes() : inputValue.getMinutes()).toString());
                var seconds = ("00" + (utc ? inputValue.getUTCSeconds() : inputValue.getSeconds()).toString());

                return year.substr(year.length - 4, 4) + "-" + month.substr(month.length - 2, 2) + "-" +
                    day.substr(day.length - 2, 2) + " " + hours.substr(hours.length - 2, 2) + ":" +
                    minutes.substr(minutes.length - 2, 2) + ":" + seconds.substr(seconds.length - 2, 2);
            } else {
                return "";
            }
        },
        normalizeDate: function (inputValue, includeTime) {
            if (isNullOrEmpty(inputValue)) { return null; }

            var result = null;

            if (inputValue instanceof Date) {
                result = this.parseDateISOString(inputValue, false);
            } else if (typeof inputValue === "string") {
                inputValue = this.parseToDate(inputValue);
                result = this.parseDateISOString(inputValue, false);
            } else {
                return null;
            }

            if (!includeTime) {
                return result.substr(0, 10);
            } else {
                return result;
            }
        },
        normalizeDateTime: function (inputValue) {
            return this.normalizeDate(inputValue, true);
        },
        getMonthName: function (dateValue) {
            if (typeof dateValue === "string") {
                dateValue = this.parseToDate(dateValue);
            }

            if (!(dateValue instanceof Date)) {
                return "N/A";
            }

            switch (window.cultureInfo) {
                default:
                case "en-US":
                    switch (dateValue.getMonth()) {
                        case 0: { return "January" };
                        case 1: { return "February" };
                        case 2: { return "March" };
                        case 3: { return "April" };
                        case 4: { return "May" };
                        case 5: { return "June" };
                        case 6: { return "July" };
                        case 7: { return "August" };
                        case 8: { return "September" };
                        case 9: { return "October" };
                        case 10: { return "November" };
                        case 11: { return "December" };
                    }
                    break;
                case "pt-BR":
                    switch (dateValue.getMonth()) {
                        case 0: { return "Janeiro" };
                        case 1: { return "Fevereiro" };
                        case 2: { return "Março" };
                        case 3: { return "Abril" };
                        case 4: { return "Maio" };
                        case 5: { return "Junho" };
                        case 6: { return "Julho" };
                        case 7: { return "Agosto" };
                        case 8: { return "Setembro" };
                        case 9: { return "Outubro" };
                        case 10: { return "Novembro" };
                        case 11: { return "Dezembro" };
                    }
                    break;
            }
        },
        parseToDate: function (inputValue) {
            if (isNullOrBlank(inputValue)) { return null; }
            if (inputValue instanceof Date) { return inputValue; }
            if (typeof inputValue != "string") { return null; }

            var parts = inputValue.split(/[^0-9]/);
            var isoFormat = inputValue.match(/\d{4}-[01]\d-[0-3]\d/);
            var currentDateTime = new Date();

            if (parts.length == 0) { return null; }

            if (isoFormat) {
                if (parts.length <= 3) {
                    while (parts.length < 3) {
                        parts.push(1);
                    }
                    return new Date(parts[0], parts[1] - 1, parts[2]);
                }

                while (parts.length < 6) {
                    parts.push(0);
                }

                if (parts[0] > 2100) { parts[0] = 2100; }
                if (parts[1] > 12) { parts[1] = 12; }
                if (parts[2] > 31) { parts[2] = 31; }
                if (parts[3] > 23) { parts[3] = 23; }
                if (parts[4] > 59) { parts[4] = 59; }
                if (parts[5] > 59) { parts[5] = 59; }

                if (parts[0] < 1900) { parts[0] = 1900; }
                if (parts[1] < 0) { parts[1] = 0; }
                if (parts[2] < 1) { parts[2] = 1; }
                if (parts[3] < 0) { parts[3] = 0; }
                if (parts[4] < 0) { parts[4] = 0; }
                if (parts[5] < 0) { parts[5] = 0; }

                return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
            } else {
                if (parts.length < 2) {
                    parts.push(currentDateTime.getMonth());
                }

                if (parts.length < 3) {
                    parts.push(currentDateTime.getFullYear());
                }

                if (parts.length == 3) {
                    return new Date(parts[2], parts[1] - 1, parts[0]);
                }

                if (parts.length < 4) {
                    parts.push(currentDateTime.getHours());
                }

                if (parts.length < 5) {
                    parts.push(currentDateTime.getMinutes());
                }

                if (parts.length < 6) {
                    parts.push(currentDateTime.getSeconds());
                }

                if (parts[2] > 2100) { parts[0] = 2100; }
                if (parts[1] > 12) { parts[1] = 12; }
                if (parts[0] > 31) { parts[2] = 31; }
                if (parts[3] > 23) { parts[3] = 23; }
                if (parts[4] > 59) { parts[4] = 59; }
                if (parts[5] > 59) { parts[5] = 59; }

                if (parts[2] < 1900) { parts[0] = 1900; }
                if (parts[1] < 0) { parts[1] = 0; }
                if (parts[0] < 1) { parts[2] = 1; }
                if (parts[3] < 0) { parts[3] = 0; }
                if (parts[4] < 0) { parts[4] = 0; }
                if (parts[5] < 0) { parts[5] = 0; }

                return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4], parts[5]);
            }
        },
        getTimePartString: function (dateValue, fullTime) {
            if (typeof dateValue === "string") {
                dateValue = this.parseToDate(dateValue);

                if (isNaN(dateValue.getTime())) {
                    return "";
                }
            }

            if (!(dateValue instanceof Date)) {
                return "";
            }

            var hour = ("00" + dateValue.getHours().toString());
            var minutes = ("00" + dateValue.getMinutes().toString());
            var seconds = ("00" + dateValue.getSeconds().toString());

            if (fullTime) {
                return (hour.substr(hour.length - 2, 2) + ":" + minutes.substr(minutes.length - 2, 2) + ":" + seconds.substr(seconds.length - 2, 2));
            } else {
                return (hour.substr(hour.length - 2, 2) + ":" + minutes.substr(minutes.length - 2, 2));
            }
        },
        timeToString: function (dateValue) {
            if (typeof dateValue === "string") {
                try { dateValue = this.parseToDate(dateValue); }
                catch (e) { return ""; }
            }

            if (!(dateValue instanceof Date) || isNaN(dateValue.getTime())) {
                return "";
            }

            var hour = dateValue.getHours();
            var minutes = dateValue.getMinutes();
            var suffix = "";

            switch (window.cultureInfo) {
                default:
                    break;
                case "en-US":
                    suffix = " am";
                    if (hour > 12) { hour = hour - 12; }
                    if (hour >= 12) { suffix = " pm" }
                    break;
            }

            hour = ("00" + hour.toString());
            minutes = ("00" + minutes.toString());

            return (hour.substr(hour.length - 2, 2) + ":" + minutes.substr(minutes.length - 2, 2)) + suffix;
        },
        dateToString: function (dateValue, displayTime) {
            if (typeof dateValue === "string") {
                try { dateValue = this.parseToDate(dateValue); }
                catch (e) { return ""; }
            }

            if (!(dateValue instanceof Date) || isNaN(dateValue.getTime())) {
                return "";
            }

            var yearPart = "0000" + dateValue.getFullYear().toString();
            var monthPart = "00" + (dateValue.getMonth() + 1).toString();
            var dayPart = "00" + dateValue.getDate().toString();
            var result = "";

            yearPart = yearPart.substr(yearPart.length - 4, 4);
            monthPart = monthPart.substr(monthPart.length - 2, 2);
            dayPart = dayPart.substr(dayPart.length - 2, 2);

            switch (window.cultureInfo) {
                default:
                case "en-US":
                    result = monthPart + "/" + dayPart + "/" + yearPart;
                    break;
                case "pt-BR":
                    result = dayPart + "/" + monthPart + "/" + yearPart;
                    break;
            }

            if (displayTime) {
                result += " " + this.timeToString(dateValue);
            }

            return result;
        },
        parseToDecimal: function (inputValue, fractionSize) {
            if (isNullOrBlank(inputValue)) {
                return null;
            }

            if (typeof inputValue === "number" && !isNaN(inputValue)) {
                return inputValue;
            }

            switch (window.cultureInfo) {
                default:
                case "en-US":
                    return eval(parseFloat(replaceAll(inputValue, ",", "")).toFixed(fractionSize));
                case "pt-BR":
                    return eval(parseFloat(replaceAll(inputValue, ".", "").replace(",", ".")).toFixed(fractionSize));
            }
        },
        decimalToString: function (input, fractionSize) {
            if (isNullOrBlank(input) || (typeof input === "number" && isNaN(input))) {
                return "";
            }
            if (isNaN(input) || (typeof input === "string")) {
                input = this.parseToDecimal(input, fractionSize);
            }

            switch (window.cultureInfo) {
                case "en-US":
                    return input.toLocaleString("en-US", { style: "decimal", useGrouping: true, minimumFractionDigits: fractionSize, maximumFractionDigits: fractionSize });
                case "pt-BR":
                    return input.toLocaleString("pt-BR", { style: "decimal", useGrouping: true, minimumFractionDigits: fractionSize, maximumFractionDigits: fractionSize });
            }
        },
        timeAmountToMinutes: function (input) {
            if (isNullOrBlank(input) || (typeof input !== "string") || input.indexOf(":") < 0) {
                return 0;
            }

            var timeAmountParts = input.split(":");

            if (timeAmountParts.length != 2) {
                return 0;
            }

            return (parseInt(timeAmountParts[0]) * 60) + parseInt(timeAmountParts[1]);
        },
        minutesToTimeAmount: function (input) {
            if (isNullOrBlank(input) || isNaN(input)) {
                return "000:00";
            }

            var hours = Math.floor(parseInt(input) / 60);
            var minutes = parseInt(input) % 60;

            hours = "000" + hours.toString();
            minutes = "00" + minutes.toString();

            return hours.substr(hours.length - 3, 3) + ":" + minutes.substr(minutes.length - 2, 2);
        },
        escapeBadChars: function (input) {
            if (isNullOrEmpty(input)) { return input; }

            var badChars = "áàâäãéèêëíìîïóòôöõúùûüçñÁÀÂÄÃÉÈÊËÍÌÎÏÓÒÔÖÕÚÙÛÜÇÑ";
            var goodChars = "aaaaaeeeeiiiiooooouuuucnAAAAAEEEEIIIIOOOOOUUUUCN";

            for (var i = 0; i < badChars.length; i++) {
                while (input.indexOf(badChars[i]) >= 0) {
                    input = input.replace(badChars[i], goodChars[i]);
                }
            }

            return input;
        }
    };
})();

function translatePage() {
    $("*[data-verb]").each(function () {
        var verb = localStorage.getItem("verb_" + this.dataset.verb);
        if (!isNullOrEmpty(verb)) { this.innerHTML = verb; }
        else { this.title = this.dataset.verb; }
    });

    $("*[data-message]").each(function () {
        var message = localStorage.getItem(this.dataset.message);
        if (!isNullOrEmpty(message)) { this.innerHTML = message; }
        else { this.title = this.dataset.message; }
    });

    $("input[data-placeholder-verb]").each(function () {
        var verb = localStorage.getItem("verb_" + this.dataset.placeholderVerb);
        if (!isNullOrEmpty(verb)) { this.placeholder = verb; }
    });

    $("*[data-tooltip]").each(function () {
        var tooltip = localStorage.getItem("verb_" + this.dataset.tooltip);
        if (!isNullOrEmpty(tooltip)) { this.title = tooltip; }
        else { this.title = this.dataset.tooltip; }
    });

    return false;
}