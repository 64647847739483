﻿// -------------------
// CONTROL INITIATION
// -------------------

function setInitialFocus() {
    $("*[data-initialfocus=true]:first").focus();
}

function preventRouteChange(scope) {
    scope.$on('$locationChangeStart', function (event) {
        var answer = confirm(window.language.getMessage("msg_changeroute"));
        if (!answer) {
            event.preventDefault();
        }
    });
}

function initializePopovers() {
    $('[data-toggle="popover"]').popover();
}

function initializeTooltips() {
    $('[data-toggle="tooltip"]').tooltip();
}

function initializeDatePickers(langCode) {
    if (!$.datepicker) { return; }

    $.datepicker.setDefaults({
        changeMonth: true,
        changeYear: true,
        yearRange: "-80:+20"
    });

    if (isNullOrBlank(langCode)) {
        langCode = window.cultureInfo;
    }

    $.datepicker.setDefaults($.datepicker.regional[langCode]);

    $("#datepickerNoOfMonths").datepicker({
        beforeShow: function () {
            setTimeout(function () {
                $('.ui-datepicker').css('z-index', 9999);
            }, 0);
        }
    });
}

if ($.mask) {
    $.mask.masks["decimalprecision-us"] = { mask: '999.999,999,999', type: 'reverse', defaultValue: '0000' };
    $.mask.masks["decimalprecision"] = { mask: '999,999.999.999', type: 'reverse', defaultValue: '0000' };
    $.mask.masks["timeamount"] = { mask: '99:999', type: 'reverse', defaultValue: '0000' };
    $.mask.masks["signed-numeric"] = { mask: '999999999', type: 'reverse', defaultValue: '+0' };
}

function initializeMasks() {

    setTimeout(function () {
        $('input[data-inputmask]').each(function () {
            var input = $(this);
            input.setMask(input.data('inputmask'));
        });
    }, 100);
}

function initializeColorPickers(colorChangedCallback) {
    $('div[data-colorcontrol]').each(function () {
        var container = $(this);
        var input = container.find("input[type='text']:first");

        container.colorpicker({ format: "hex" }).on("hidePicker",
            function (e) {
                if (!isNull(colorChangedCallback) && colorChangedCallback instanceof Function) {
                    colorChangedCallback(input);
                }
            });

        container.colorpicker('setValue', input.val());
    });
}

function initializeComboboxes(selector) {
    if (isNullOrBlank(selector)) {
        selector = "select[data-combobox]";
    }

    var cleanupComboboxText = function (input) {
        var container = input.data('select2').$container.find("span.select2-selection__rendered");
        var selectedItemContent = container.contents().last();
        var choiceText = selectedItemContent.text();
        choiceText = replaceAll(choiceText, "├", "");
        choiceText = replaceAll(choiceText, "│", "");
        choiceText = replaceAll(choiceText, "└", "");
        choiceText = replaceAll(choiceText, "─", "");
        choiceText = replaceAll(choiceText, " ", ""); /* this isn't empty space (this is &emsp;) */
        selectedItemContent[0].textContent = choiceText;
    };

    if ($.fn.select2) {
        setTimeout(function () {
            $(selector).each(function () {
                var input = $(this);
                var isMultiple = !isNullOrBlank(input.attr("multiple"));
                var isHierarchy = input.data("hierarchy");

                input.css("width", "100%");
                input.select2({
                    theme: "bootstrap",
                    allowClear: !isMultiple,
                    placeholder: window.language.getVerb("select")
                });

                if (isHierarchy) {
                    input.data('select2').$dropdown.addClass("hierarchy");
                    cleanupComboboxText(input);
                }
            }).change(function (e) {
                var input = $(this);
                var isHierarchy = input.data("hierarchy");

                if (isHierarchy) { cleanupComboboxText(input); }
            });
        }, 100);
    }
}

function initializeSlider(sliderSelector, currentValue, minValue, maxValue, sliderStep, onSlide, onValueChange) {
    var sliderArgs = {
        value: currentValue,
        min: minValue,
        max: maxValue
    };

    if (onSlide instanceof Function) {
        sliderArgs.slide = function (event, ui) {
            onSlide(ui.value);
        };
    }

    if (onValueChange instanceof Function) {
        sliderArgs.change = function (event, ui) {
            onValueChange(ui.value);
        };
    }

    if (!isNull(sliderStep)) {
        sliderArgs.step = sliderStep;
    }

    $(sliderSelector).slider(sliderArgs);
}

function setDropdownInitialValue(ctrlSelector, value, text) {
    var selectCtrl = $(ctrlSelector).get(0);
    var containsOption = false;

    for (var i = 0; i < selectCtrl.options.length; i++) {
        if (selectCtrl.options[i].value.toString().toUpperCase() == value.toString().toUpperCase()) {
            containsOption = true;
            break;
        }
    }

    selectCtrl = $(selectCtrl);

    if (!containsOption) {
        selectCtrl.append($("<option />").val(value).text(text).attr("selected", "selected"));
    }

    selectCtrl.val(value);
}

// -------------------

function disableControls(container) {
    var controls = [];

    controls.push(container.getElementsByTagName("INPUT"));
    controls.push(container.getElementsByTagName("TEXTAREA"));
    controls.push(container.getElementsByTagName("BUTTON"));
    controls.push(container.getElementsByTagName("A"));

    for (var i = 0; i < controls.length; i++) {
        controls[i].disabled = "disabled";
    }
}

function getQueryString(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(location.href);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function applyOnScope(scope, ctrl, func) {
    // BEGIN: Medida Temporária enquanto houver cache
    if (ctrl instanceof Function && func == undefined) {
        func = ctrl;
    }
    // END

    var shouldApply = true;
    var currentScope = scope;

    while (!isNull(currentScope)) {
        if (currentScope.$$phase) {
            shouldApply = false;
            break;
        }
        currentScope = currentScope.$parent;
    }

    if (window.lastScroll === null || window.lastScroll === undefined) {
        window.lastScroll = $(window).scrollTop();
        if (window.scrollRecovery) {
            clearTimeout(window.scrollRecovery);
        }
    }

    if (shouldApply) {
        scope.$apply(func);
    } else {
        func();
    }

    if (ctrl.loading === false && window.lastScroll != null && !window.scrollRecovery) {
        window.scrollRecovery = setTimeout(function () {
            if (window.lastScroll > $(window).scrollTop()) {
                $(window).scrollTop(window.lastScroll);
            }

            window.lastScroll = null;
            window.scrollRecovery = null;
        }, 500);
    }
}

function isNullOrBlank(value) {
    if (isNull(value)) {
        return true;
    } else {
        return (value.toString().trim().length === 0);
    }
}

function isNullOrEmpty(value) {
    if (isNull(value)) {
        return true;
    } else if (value instanceof Array || value instanceof jQuery) {
        return (value.length === 0);
    } else {
        return (value.toString().length === 0);
    }
}

function isNull(value) {
    return (value == null || value == undefined);
}

function isFunction(functionToCheck) {
    var getType = {};
    return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

function applyColumnFilters(onColumnSort, onColumnFilter) {
    $("th[data-columncontrol='true']").each(function () {
        var columnHeader = this;
        var colTable = columnHeader.dataset.columntable;
        var colType = columnHeader.dataset.columntype;
        var colName = columnHeader.dataset.columnname;

        if (colType == null || colName == null) { return true; }

        columnHeader.onColumnSort = onColumnSort;
        columnHeader.onColumnFilter = onColumnFilter;

        $(columnHeader).find("a:first").popover({
            trigger: "click",
            placement: "bottom",
            container: "body",
            html: true,
            title: "Sort And Filter",
            content: function () {
                return createFilterContent((isNullOrEmpty(colTable) ? "" : colTable), colType, colName,
                    (isNullOrEmpty(columnHeader.dataset.firstvalue) ? "" : columnHeader.dataset.firstvalue),
                    (isNullOrEmpty(columnHeader.dataset.lastvalue) ? "" : columnHeader.dataset.lastvalue));
            }
        }).on("shown.bs.popover", function () {
            $('input[data-inputmask]').each(function () {
                var input = $(this);
                input.setMask(input.data('inputmask'));
            });

            initializeDatePickers(window.cultureInfo);
        });
    });
}

function createFilterContent(colTable, colType, colName, firstValue, lastValue) {
    var filterContent = "";
    filterContent += "<div>";
    filterContent += "    <button type='button' class='btn btn-default btn-xs pull-right' style='margin-left: 10px;' onclick='javascript:return closePopover(this, \"" + colTable + "\", \"" + colName + "\");'>";
    filterContent += "        <span class='glyphicon glyphicon-remove' aria-hidden='true'></span> ";
    filterContent += "    </button>";
    filterContent += "    <div class='btn-group' role='group' aria-hidden='true'>";
    filterContent += "        <button type='button' class='btn btn-default btn-xs' onclick='javascript:return sortColumn(this, \"" + colTable + "\", \"" + colName + "\", false, null);'>";
    filterContent += "            <span class='glyphicon glyphicon-ban-circle' aria-hidden='true'></span> ";
    filterContent += "        </button>";
    filterContent += "        <button type='button' class='btn btn-default btn-xs' onclick='javascript:return sortColumn(this, \"" + colTable + "\", \"" + colName + "\", true, false);'>";
    filterContent += "            <span class='glyphicon glyphicon-sort-by-alphabet' aria-hidden='true'></span> ";
    filterContent += "        </button>";
    filterContent += "        <button type='button' class='btn btn-default btn-xs' onclick='javascript:return sortColumn(this, \"" + colTable + "\", \"" + colName + "\", true, true);'>";
    filterContent += "            <span class='glyphicon glyphicon-sort-by-alphabet-alt' aria-hidden='true'></span> ";
    filterContent += "        </button>";
    filterContent += "    </div>";

    switch (colType) {
        case "text":
            filterContent += "    <div class='input-group input-group-sm' style='margin-top: 10px;'>";
            filterContent += "        <input type='text' class='form-control' placeholder='Filter Value' value='" + firstValue + "' />";
            filterContent += "        <div class='input-group-btn'>";
            filterContent += "            <button type='button' class='btn btn-default' onclick='javascript:return filterColumn(this, \"" + colTable + "\", \"" + colName + "\", \"text\");'>";
            filterContent += "                <span class='glyphicon glyphicon-filter' aria-hidden='true'></span> ";
            filterContent += "            </button>";
            filterContent += "            <button type='button' class='btn btn-default' onclick='javascript:return filterColumn(this, \"" + colTable + "\", \"" + colName + "\", false);'>";
            filterContent += "                <span class='glyphicon glyphicon-erase' aria-hidden='true'></span> ";
            filterContent += "            </button>";
            filterContent += "        </div>";
            filterContent += "    </div>";
            break;
        case "numeric":
            filterContent += "    <div class='form-inline' style='margin-top: 10px;'>";
            filterContent += "        <div class='form-group' style='width: 100px;'>";
            filterContent += "            <input type='text' class='form-control input-sm' placeholder='Initial Value' value='" + firstValue + "' data-inputmask='numeric' style='width: 100%;' />";
            filterContent += "        </div>";
            filterContent += "        <div class='form-group' style='width: 100px;'>";
            filterContent += "            <input type='text' class='form-control input-sm' placeholder='Final Value' value='" + lastValue + "' data-inputmask='numeric' style='width: 100%;' />";
            filterContent += "        </div>";
            filterContent += "    </div>";
            filterContent += "    <div class='form-inline' style='margin-top: 10px;'>";
            filterContent += "        <div class='form-group' style='width: 100px;'>";
            filterContent += "           <button type='button' class='btn btn-default btn-sm' onclick='javascript:return filterColumn(this, \"" + colTable + "\", \"" + colName + "\", \"numeric\");' style='width: 100%;'>";
            filterContent += "               <span class='glyphicon glyphicon-filter' aria-hidden='true'></span> ";
            filterContent += "           </button>";
            filterContent += "        </div>";
            filterContent += "        <div class='form-group' style='width: 100px;'>";
            filterContent += "           <button type='button' class='btn btn-default btn-sm' onclick='javascript:return filterColumn(this, \"" + colTable + "\", \"" + colName + "\", false);' style='width: 100%;'>";
            filterContent += "               <span class='glyphicon glyphicon-erase' aria-hidden='true'></span> ";
            filterContent += "           </button>";
            filterContent += "        </div>";
            filterContent += "    </div>";
            break;
        case "date":
            filterContent += "    <div class='form-inline' style='margin-top: 10px;'>";
            filterContent += "        <div class='form-group' style='width: 100px;'>";
            filterContent += "            <input type='text' class='form-control input-sm' placeholder='Initial Value' value='" + firstValue + "' data-inputmask='" + window.formatter[window.cultureInfo].inputDateMask + "' data-datepicker='true' style='width: 100%;' />";
            filterContent += "        </div>";
            filterContent += "        <div class='form-group' style='width: 100px;'>";
            filterContent += "            <input type='text' class='form-control input-sm' placeholder='Final Value' value='" + lastValue + "' data-inputmask='" + window.formatter[window.cultureInfo].inputDateMask + "' data-datepicker='true' style='width: 100%;' />";
            filterContent += "        </div>";
            filterContent += "    </div>";
            filterContent += "    <div class='form-inline' style='margin-top: 10px;'>";
            filterContent += "        <div class='form-group' style='width: 100px;'>";
            filterContent += "           <button type='button' class='btn btn-default btn-sm' onclick='javascript:return filterColumn(this, \"" + colTable + "\", \"" + colName + "\", \"date\");' style='width: 100%;'>";
            filterContent += "               <span class='glyphicon glyphicon-filter' aria-hidden='true'></span> ";
            filterContent += "           </button>";
            filterContent += "        </div>";
            filterContent += "        <div class='form-group' style='width: 100px;'>";
            filterContent += "           <button type='button' class='btn btn-default btn-sm' onclick='javascript:return filterColumn(this, \"" + colTable + "\", \"" + colName + "\", false);' style='width: 100%;'>";
            filterContent += "               <span class='glyphicon glyphicon-erase' aria-hidden='true'></span> ";
            filterContent += "           </button>";
            filterContent += "        </div>";
            filterContent += "    </div>";
            break;
    }

    filterContent += "</div>";

    return filterContent;
}

function closePopover(sender, columnTable, columnName) {
    sender.disabled = true;

    var columnHeader = null;

    if (!isNullOrEmpty(columnTable)) {
        columnHeader = $("th[data-columntable='" + columnTable + "'][data-columnname='" + columnName + "']:first");
    } else {
        columnHeader = $("th[data-columnname='" + columnName + "']:not([data-columntable]):first");
    }

    var columnLink = null;

    if (columnHeader.length > 0) {
        columnLink = columnHeader.find("a:first");

        if (columnLink.length > 0) {
            columnLink.get(0).click();
        }
    }

    return false;
}

function sortColumn(sender, columnTable, columnName, applySort, descending) {
    sender.disabled = true;

    var columnHeader = null;

    if (!isNullOrEmpty(columnTable)) {
        columnHeader = $("th[data-columntable='" + columnTable + "'][data-columnname='" + columnName + "']:first");
    } else {
        columnHeader = $("th[data-columnname='" + columnName + "']:not([data-columntable]):first");
    }

    var columnLink = null;

    if (columnHeader.length > 0) {
        var element = columnHeader.get(0);
        if (!applySort) {
            element.onColumnSort(element, null, null, false);
        } else {
            element.onColumnSort(element, columnTable, columnName, descending);
        }

        columnLink = columnHeader.find("a:first");

        if (columnLink.length > 0) {
            columnLink = columnLink.get(0);

            $("th[data-columncontrol] span.glyphicon").remove();

            if (applySort) {
                if (descending) {
                    $("<span class='glyphicon glyphicon-chevron-down' aria-hidden='true' style='margin-left: 5px;'></span>").insertAfter(columnLink);
                } else {
                    $("<span class='glyphicon glyphicon-chevron-up' aria-hidden='true' style='margin-left: 5px;'></span>").insertAfter(columnLink);
                }
            }

            columnLink.click();
        }
    }
    return false;
}

function filterColumn(sender, columnTable, columnName, filterType) {
    sender.disabled = true;

    var filterContainer = $(sender).closest("div.popover-content");

    var columnHeader = null;

    if (!isNullOrEmpty(columnTable)) {
        columnHeader = $("th[data-columntable='" + columnTable + "'][data-columnname='" + columnName + "']:first");
    } else {
        columnHeader = $("th[data-columnname='" + columnName + "']:not([data-columntable]):first");
    }

    var columnLink = null;
    var firstValue = null;
    var lastValue = null;

    if (columnHeader.length > 0) {
        var element = columnHeader.get(0);

        firstValue = filterContainer.find("input[type=text]:first").val();
        lastValue = filterContainer.find("input[type=text]:last").val();

        if (!filterType) {
            element.dataset.firstvalue = "";
            element.dataset.lastvalue = "";
            element.onColumnFilter(element, columnTable, columnName, false, null, null);
        } else {
            element.dataset.firstvalue = firstValue;
            element.dataset.lastvalue = lastValue;
            element.onColumnFilter(element, columnTable, columnName, filterType, firstValue, lastValue);
        }

        columnLink = columnHeader.find("a:first");

        if (columnLink.length > 0) {
            columnLink = columnLink.get(0);

            if (!filterType) {
                columnHeader.find("i.fa.fa-filter").hide();
            } else {
                columnHeader.find("i.fa.fa-filter").show();
            }

            columnLink.click();
        }
    }
    return false;
}

function prepareFilters(filters, columnTable, columnName, filterType, firstValue, lastValue) {
    if (isNull(filters)) { filters = new Array(); }

    for (var i = filters.length - 1; i >= 0; i--) {
        if (filters[i].table === columnTable && filters[i].field === columnName) {
            filters.splice(i, 1);
        }
    }

    if (filterType != false && !isNullOrEmpty(firstValue)) {
        switch (filterType) {
            case "text":
                filters.push({
                    table: columnTable,
                    field: columnName,
                    operator: "contains",
                    value: firstValue
                });
                break;
            case "numeric":
                filters.push({
                    table: columnTable,
                    field: columnName,
                    operator: "equalorgreaterthan",
                    value: firstValue
                });

                if (!isNullOrEmpty(lastValue)) {
                    filters.push({
                        table: columnTable,
                        field: columnName,
                        operator: "equalorlessthan",
                        value: lastValue
                    });
                }
                break;
            case "date":
                firstValue = window.converter.normalizeDate(firstValue);

                if (!isNullOrEmpty(firstValue)) {
                    firstValue += "T00:00:00.000Z";
                }

                filters.push({
                    table: columnTable,
                    field: columnName,
                    operator: "equalorgreaterthan",
                    value: firstValue
                });

                if (!isNullOrEmpty(lastValue)) {

                    lastValue = window.converter.normalizeDate(lastValue);

                    if (!isNullOrEmpty(lastValue)) {
                        lastValue += "T23:59:59.999Z";
                    }

                    filters.push({
                        table: columnTable,
                        field: columnName,
                        operator: "equalorlessthan",
                        value: lastValue
                    });
                }
                break;
        }
    }

    return filters;
}

function prepareSingleSearchSetting(columnName, filterValue, operator) {
    return {
        "settings": {
            "filters": [{
                field: columnName,
                operator: operator || "equal",
                value: filterValue
            }]
        }
    };
}

function generateNewGUID() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function reloadRoute() {
    window.location = "./loading?goto=" + encodeURIComponent(window.location);
    event.preventDefault();
    event.stopPropagation();
    return false;
}

function launchContainer(route, fullScreen, dismissCallback) {
    var dynamicModalContainer = $("#divDynamicModalContainer");

    if (isNullOrEmpty(dynamicModalContainer)) {
        dynamicModalContainer = createDynamicModalContainer();
    }

    if (fullScreen) {
        dynamicModalContainer.find("div.modal-dialog").addClass("modal-lg").css("min-width", "90%");
    } else {
        dynamicModalContainer.find("div.modal-dialog").removeClass("modal-lg").css("min-width", "");
    }

    var divDynamicModalContainerBody = $("#divDynamicModalContainerBody");
    var divDynamicModalContainerFooter = $("#divDynamicModalContainerFooter");

    divDynamicModalContainerBody.empty();

    var contentFrame = document.createElement("iframe");
    contentFrame = $(contentFrame);
    contentFrame.css("width", "100%");
    contentFrame.css("min-height", "400px");
    contentFrame.attr("frameborder", "0").attr("marginheight", "0").attr("marginwidth", "0");
    contentFrame.appendTo(divDynamicModalContainerBody);

    dynamicModalContainer.modal("show").off("shown.bs.modal").on("shown.bs.modal", function () {
        contentFrame.attr("src", (window.baseAppPath || "") + "/container/" + route);
    }).off("hide.bs.modal").on("hide.bs.modal", function () {
        if (dismissCallback != null && dismissCallback instanceof Function) {
            dismissCallback(contentFrame.get(0));
        }
    });
}

function createDynamicModalContainer() {
    var dynamicModalContainer = document.createElement('div');
    dynamicModalContainer.id = "divDynamicModalContainer";
    dynamicModalContainer = $(dynamicModalContainer);
    dynamicModalContainer.addClass("modal fade containerModal").attr("tabindex", "-1").attr("role", "dialog").appendTo($("body"));

    var innerModal = document.createElement("div");
    innerModal = $(innerModal);
    innerModal.addClass("modal-dialog").appendTo(dynamicModalContainer);

    var modalContent = document.createElement("div");
    modalContent = $(modalContent);
    modalContent.addClass("modal-content").appendTo(innerModal);

    var modalBody = document.createElement("div");
    modalBody.id = "divDynamicModalContainerBody";
    modalBody = $(modalBody);
    modalBody.addClass("modal-body").appendTo(modalContent);

    var modalFooter = document.createElement("div");
    modalFooter.id = "divDynamicModalContainerFooter";
    modalFooter = $(modalFooter);
    modalFooter.addClass("modal-footer").appendTo(modalContent);

    var closeButton = document.createElement("button");
    closeButton = $(closeButton);
    closeButton.attr("type", "button").addClass("btn btn-danger").attr("data-dismiss", "modal");
    closeButton.html(window.language.getVerb("close"));
    closeButton.appendTo(modalFooter);

    return dynamicModalContainer;
}

function replaceAll(text, oldValue, newValue) {
    if (isNullOrBlank(text)) { return text; }
    while (text.indexOf(oldValue) >= 0) {
        text = text.replace(oldValue, newValue);
    }
    return text;
}

function displaySuccessToastMessage() {
    displayToastMessage(window.language.getMessage("msg_successfuloperation"), 0);
}

function displayToastMessage(message, warningLevel) {
    if (isNullOrBlank(message)) { return false; }

    var messages = message.split("\r\n");

    var title = null;
    var icon = null;
    var bgColor = null;
    var loaderBg = null;
    var hideAfter = false;

    while (isNullOrBlank(messages[messages.length - 1])) { messages.pop(); }

    if (messages.length == 0) { return false; }

    switch (warningLevel) {
        case 0:
            title = window.language.getVerb("done");
            icon = "success";
            bgColor = "#00a65a";
            loaderBg = "#008d4c";
            hideAfter = 1000;
            break;
        case 1:
            title = window.language.getVerb("warning");
            icon = "warning";
            bgColor = "#f39c12";
            loaderBg = "#e08e0b";
            hideAfter = 4000;
            break;
        case 2:
            title = window.language.getVerb("error");
            icon = "error";
            bgColor = "#dd4b39";
            loaderBg = "#d73925";
            hideAfter = 2000;
            break;
        default:
            title = window.language.getVerb("information");
            icon = "info";
            bgColor = "#00c0ef";
            loaderBg = "#00acd6";
            hideAfter = 10000;
            break;
    }

    $.toast({
        heading: title,
        text: messages,
        icon: icon,
        position: 'bottom-right',
        bgColor: bgColor,
        textColor: "#FFFFFF",
        loaderBg: loaderBg,
        hideAfter: hideAfter
    });
}

function createLocalDownload(filename, content) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:application/octet-stream;charset=utf-8;base64,' + content);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

function MultipleDatepicker(d = 0) {
 
    $('.fc-datepicker').daterangepicker({
        showOtherMonths: true,
        selectOtherMonths: true
    });
	 
    var today = new Date(); 
    var dd = today.getDate()+d; 
    var mm = today.getMonth()+1; //January is 0! 
    var yyyy = today.getFullYear(); 
    if(dd<10){ dd='0'+dd } 
    if(mm<10){ mm='0'+mm } 
    var today = dd+'/'+mm+'/'+yyyy; 

    $('#datepickerNoOfMonths').daterangepicker({
        showOtherMonths: true,
        selectOtherMonths: true,
        numberOfMonths: 2,
        autoApply: true,
        minDate:today,
        endDate:today,
        "locale": { 
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Aplicar",
            "cancelLabel": "Cancelar",
            "daysOfWeek": [
                "Dom",
                "Seg",
                "Ter",
                "Qua",
                "Qui",
                "Sex",
                "Sab"
            ],
            "monthNames": [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro"
            ],
            "firstDay": 0
        }
    });
}

function ButtonCopy(barCode) {
    var copyElement = document.createElement("span");
    copyElement.appendChild(document.createTextNode(barCode));
    copyElement.id = 'textCopy';
    document.body.append(copyElement);

    var range = document.createRange();
    range.selectNode(copyElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);

    document.execCommand("copy"); 
    window.getSelection().removeAllRanges(); 
    copyElement.remove(); 
}

function DateRangePickerValue() {
    var dates = $('#datepickerNoOfMonths').val();
    var datesSplit = dates.split("-");

    datesSplit[0] = datesSplit[0].substr(6, 4) + "-" + datesSplit[0].substr(3, 2) + "-" + datesSplit[0].substr(0, 2)
    datesSplit[1] = datesSplit[1].substr(7, 4) + "-" + datesSplit[1].substr(4, 2) + "-" + datesSplit[1].substr(1, 2)

    return datesSplit;
}


function viewPassword(name){ 
    var type = document.getElementById(name); 
    
    if(type.type == "password"){
        type.type ="text";
    }else{
        type.type ="password";
    }
}

// USER SETTINGS AND SNIPPETS
(function () {
    window.getUserSettings = function () {
        var userSettings = sessionStorage.getItem("usersettings");

        if (!isNullOrBlank(userSettings)) {
            userSettings = JSON.parse(userSettings);
        } else {
            userSettings = {};
        }

        if (isNull(userSettings.Settings)) { userSettings.Settings = {} }
        if (isNull(userSettings.Settings.Preferences)) { userSettings.Settings.Preferences = [] }
        if (isNull(userSettings.Settings.Snippets)) { userSettings.Settings.Snippets = [] }

        return userSettings;
    };

    window.getPreference = function (key, defaultValue) {
        if (isNull(key)) { return defaultValue; }

        var config = window.getUserSettings();

        for (var i = 0, preference = null; (preference = config.Settings.Preferences[i]); i++) {
            if (preference.key == key) {
                return preference.value;
            }
        }

        return defaultValue;
    };

    window.setPreference = function (key, value) {
        var done = false;

        if (isNull(key)) { return done; }

        var config = window.getUserSettings();

        for (var i = 0, preference = null; (preference = config.Settings.Preferences[i]); i++) {
            if (preference.key == key) {
                preference.value = value;
                done = true;
            }
        }

        if (!done) {
            config.Settings.Preferences.push({ "key": key, "value": value });
        }

        sessionStorage.setItem("usersettings", JSON.stringify(config));
        window.saveUserSettings();

        return done;
    };

    window.getSnippetConfig = function (config, key) {
        if (isNull(key)) { return null; }

        var snippetConfig = null;

        for (var i = 0, snippet = null; (snippet = config.Settings.Snippets[i]); i++) {
            if (snippet.key == key) {
                snippetConfig = snippet;
            }
        }

        return snippetConfig;
    };

    window.getSnippetOption = function (snippet, key, defaultValue) {
        if (isNull(snippet.options)) { snippet.options = {} };
        if (!isNull(snippet.options[key])) {
            return snippet.options[key];
        } else {
            return defaultValue;
        }
    };

    window.setSnippetOption = function (config, snippet, key, value) {
        if (isNull(snippet.options)) { snippet.options = {} };

        snippet.options[key] = value;

        sessionStorage.setItem("usersettings", JSON.stringify(config));
        window.saveUserSettings();
    };

    window.minimizeSnippet = function (config, snippet) {
        if (isNull(snippet.minimized)) {
            snippet.minimized = false;
        }

        snippet.minimized = !snippet.minimized;
        sessionStorage.setItem("usersettings", JSON.stringify(config));
        window.saveUserSettings();
    };

    window.removeSnippet = function (config, snippet) {
        var snippetCollection = config.Settings.Snippets;
        var removed = false;

        for (var i = 0, item = null; (item = snippetCollection[i]); i++) {
            if (item.key == snippet.key) {
                snippetCollection.splice(i, 1);
                removed = true;
            }
        }

        if (removed) {
            sessionStorage.setItem("usersettings", JSON.stringify(config));
            window.saveUserSettings();

            for (var i = 0, available = null; (available = window.snippets[i]); i++) {
                if (snippet.key == available.key) { available.Added = false; };
                break;
            }
        }

        return removed;
    };

    window.saveUserSettings = function () {
        var userSettings = sessionStorage.getItem("usersettings");

        if (isNull(userSettings)) { return false; }

        userSettings = JSON.parse(userSettings);

        apiServiceCall("framework", "svcUser", "SaveSettings", { "settings": userSettings },
            function (result, message) {
                displayToastMessage(window.language.getMessage("msg_user_settings_saved"), null);
            }, function (message, warningLevel) {
                displayToastMessage(message, warningLevel);
            });
    };

})();